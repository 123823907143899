var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-warper", attrs: { id: "app-warper" } },
    [
      _c(
        "el-container",
        { attrs: { id: "app-container" } },
        [
          _c("el-header", [_c("nav-bar")], 1),
          _c("el-main", [
            _c(
              "div",
              {
                staticStyle: { position: "relative" },
                attrs: { id: "home-div" },
              },
              [
                _c("section", {
                  staticStyle: { position: "absolute", top: "-80px" },
                  attrs: { id: "home" },
                }),
                _c("div", { staticClass: "one-stop-frame" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v(" 企业数字资产一站式服务 "),
                  ]),
                  _c("div", { staticClass: "background" }),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { position: "relative" },
                attrs: { id: "server-div" },
              },
              [
                _c("section", {
                  staticStyle: { position: "absolute", top: "-80px" },
                  attrs: { id: "server" },
                }),
                _c("div", { staticClass: "service-base" }, [
                  _c("div", { staticClass: "edge-1" }),
                  _c("div", { staticClass: "edge-indicate" }, [
                    _c("img", { attrs: { src: require("@/assets/yezi.png") } }),
                  ]),
                  _c("div", { staticClass: "header" }, [
                    _vm._v(" 企业数字资产服务基座 "),
                  ]),
                  _c("div", { staticClass: "sub-header" }, [
                    _vm._v(" 企业数字资产的守门人 "),
                  ]),
                  _c("div", { staticClass: "blocks" }, [
                    _c("div", { staticClass: "item manage" }, [
                      _c("div", { staticClass: "decorate" }, [
                        _c("img", {
                          attrs: { src: require("@/assets/block_quota.png") },
                        }),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("企业的数字身份证"),
                        ]),
                        _c("div", { staticClass: "intro" }, [
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "使用密码学技术为企业生成唯一的数字身份。使用数字身份对企业内数字资产进行签名，可证明企业对数字资产的所有权。"
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "企业数字身份保管于基座内置的专用硬件加密芯片，没有密钥丢失风险。全套签名方案使用国密算法，符合国家安全标准要求。"
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "高性能软硬件一体架构设计，支持海量高并发的企业数字资产快速签名认证，有效避免延迟认证带来的侵权风险。"
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "item protect" }, [
                      _c("div", { staticClass: "decorate" }, [
                        _c("img", {
                          attrs: { src: require("@/assets/block_quota.png") },
                        }),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("全面保护企业内部数字资产"),
                        ]),
                        _c("div", { staticClass: "intro" }, [
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "服务基座部署在企业内部，保证数字资产永不离开企业内网"
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "快速连接企业内所有的数字资产相关系统，全面保护分散在企业内部、快速产生的海量数字资产"
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "海量可选扩展，支持企业网盘、邮件系统、源代码、合同及文档管理系统、员工/客户沟通交流软件、ERP、CRM和PLM等系统的快速接入。"
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "item issue" }, [
                      _c("div", { staticClass: "decorate" }, [
                        _c("img", {
                          attrs: { src: require("@/assets/block_quota.png") },
                        }),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("有效的司法合规保障"),
                        ]),
                        _c("div", { staticClass: "intro" }, [
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "服务基座直接连接数字产权服务中心，实时将数字资产的签名发送到数字产权服务中心进行存证登记。"
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "数字产权服务中心使用区块链技术保证数据一经记录无法篡改。可有效证明数字资产的生成时间和所有权。"
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "全部认证数据实时同步至相关的公证、行政和司法机构。在出现纠纷时，该数据可直接做为证据进行使用，具备有效的法律效力。"
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "blocks mobile", attrs: { id: "blocks" } },
                    [
                      _c("div", { staticClass: "header mobile" }, [
                        _vm._v(" 企业数字资产服务基座 "),
                      ]),
                      _c("div", { staticClass: "sub-header mobile" }, [
                        _vm._v(" 企业数字资产的守门人 "),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "item manage animation",
                          attrs: { id: "animate-1" },
                          on: { touchstart: _vm.animationStart },
                        },
                        [
                          _c("div", { staticClass: "decorate" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/block_quota.png"),
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("企业的数字身份证"),
                            ]),
                            _c("div", { staticClass: "intro" }, [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    "使用密码学技术为企业生成唯一的数字身份。使用数字身份对企业内数字资产进行签名，可证明企业对数字资产的所有权。"
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "企业数字身份保管于基座内置的专用硬件加密芯片，没有密钥丢失风险。全套签名方案使用国密算法，符合国家安全标准要求。"
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "高性能软硬件一体架构设计，支持海量高并发的企业数字资产快速签名认证，有效避免延迟认证带来的侵权风险。"
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "item protect animation",
                          attrs: { id: "animate-2" },
                          on: { touchstart: _vm.animationStart },
                        },
                        [
                          _c("div", { staticClass: "decorate" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/block_quota.png"),
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("全面保护企业内部数字资产"),
                            ]),
                            _c("div", { staticClass: "intro" }, [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    "服务基座部署在企业内部，保证数字资产永不离开企业内网"
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "快速连接企业内所有的数字资产相关系统，全面保护分散在企业内部、快速产生的海量数字资产"
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "海量可选扩展，支持企业网盘、邮件系统、源代码、合同及文档管理系统、员工/客户沟通交流软件、ERP、CRM和PLM等系统的快速接入。"
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "item issue animation",
                          attrs: { id: "animate-3" },
                          on: { touchstart: _vm.animationStart },
                        },
                        [
                          _c("div", { staticClass: "decorate" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/block_quota.png"),
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("有效的司法合规保障"),
                            ]),
                            _c("div", { staticClass: "intro" }, [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    "服务基座直接连接数字产权服务中心，实时将数字资产的签名发送到数字产权服务中心进行存证登记。"
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "数字产权服务中心使用区块链技术保证数据一经记录无法篡改。可有效证明数字资产的生成时间和所有权。"
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    "全部认证数据实时同步至相关的公证、行政和司法机构。在出现纠纷时，该数据可直接做为证据进行使用，具备有效的法律效力。"
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "dots" }, [
                        _c("div", {
                          staticClass: "dot",
                          class: { active: _vm.curanimate == "animate-1" },
                        }),
                        _c("div", {
                          staticClass: "dot",
                          class: { active: _vm.curanimate == "animate-2" },
                        }),
                        _c("div", {
                          staticClass: "dot",
                          class: { active: _vm.curanimate == "animate-3" },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _c("div", [
                  _c("img", {
                    staticClass: "server-bg",
                    attrs: { src: require("@/assets/server-bg.png") },
                  }),
                ]),
              ]
            ),
            _c(
              "section",
              {
                staticStyle: { position: "relative" },
                attrs: { id: "solutions-div" },
              },
              [
                _c("section", {
                  staticStyle: { position: "absolute", top: "-80px" },
                  attrs: { id: "solutions" },
                }),
                _c("div", { staticClass: "products-header" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/prd_title_decorate.png") },
                  }),
                  _c("div", { staticClass: "prd-title" }, [
                    _vm._v("我们的产品"),
                  ]),
                ]),
                _c("div", { staticClass: "digit-asset product" }, [
                  _c("div", { staticClass: "intro" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("数字知识产权服务平台"),
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _c("p", [
                        _vm._v(
                          "数字知识产权服务平台以“数字化确权”作为知识资产保护的起点，快速为企业及个人建立数字资产保险箱，保护企业在每天的经营中产生的大量无法做为传统知识产权进行保护的知识产权和商业秘密。并通过隐私计算、人工智能和区块链技术促进知识产权价值的全面释放。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 目前数字知识产权服务平台已为中国湖南自贸区知识产权服务中心、上海区块链工程技术研究中心、中国铁建重工集团等八十多家政府部门、科研机构和创新型企业提供服务。 "
                        ),
                      ]),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://ip.hetutec.com/",
                          target: "_blank",
                        },
                      },
                      [
                        _c("div", { staticClass: "btn clickable" }, [
                          _vm._v(" 查看详情 "),
                          _c("div", { staticClass: "indicator" }, [
                            _c("div"),
                            _c("div"),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "img" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/digital-assets-platform.png"),
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "miks-bg" }, [
                  _c("div", { staticClass: "edge-2" }),
                  _c("div", { staticClass: "miks product" }, [
                    _c("div", { staticClass: "img" }, [
                      _c("img", {
                        attrs: { src: require("@/assets/miks-bg.png") },
                      }),
                    ]),
                    _c("div", { staticClass: "intro" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Miks - 企业数字化协作创新平台"),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _c("p", [
                          _vm._v(
                            "以个人级别的知识产权确权做为基础，促进企业成员间的共享和沟通交流，打破企业内部信息壁垒，帮企业导入更多外部的相关信息，整体提高企业的创新生产力，提升创新型企业运行效率。 "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " 在保护企业数据隐私安全的前提下，打破企业间信息壁垒，促进企业间的信息互通，推动产业链上下游企业高效协同创新，实现行业整体技术水平和效率的提升。 "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " Miks的客户包括Continental、博泽集团、HAKUHODO等国内外著名的企业集团以及大量的中小创新型企业。 "
                          ),
                        ]),
                      ]),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://wemiks.com/",
                            target: "_blank",
                          },
                        },
                        [
                          _c("div", { staticClass: "btn clickable" }, [
                            _vm._v(" 查看详情 "),
                            _c("div", { staticClass: "indicator" }, [
                              _c("div"),
                              _c("div"),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "miks product mobile" }, [
                    _c("div", { staticClass: "intro" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Miks - 企业数字化协作创新平台"),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _c("p", [
                          _vm._v(
                            "以个人级别的知识产权确权做为基础，促进企业成员间的共享和沟通交流，打破企业内部信息壁垒，帮企业导入更多外部的相关信息，整体提高企业的创新生产力，提升创新型企业运行效率。 "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " 在保护企业数据隐私安全的前提下，打破企业间信息壁垒，促进企业间的信息互通，推动产业链上下游企业高效协同创新，实现行业整体技术水平和效率的提升。 "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " Miks的客户包括Continental、博泽集团、HAKUHODO等国内外著名的企业集团以及大量的中小创新型企业。 "
                          ),
                        ]),
                      ]),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://wemiks.com/",
                            target: "_blank",
                          },
                        },
                        [
                          _c("div", { staticClass: "btn clickable" }, [
                            _vm._v(" 查看详情 "),
                            _c("div", { staticClass: "indicator" }, [
                              _c("div"),
                              _c("div"),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "img" }, [
                      _c("img", {
                        attrs: { src: require("@/assets/miks-bg.png") },
                      }),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "yuanben-bg" }, [
                  _c("div", { staticClass: "edge-3" }),
                  _c("div", { staticClass: "yuanben product" }, [
                    _c("div", { staticClass: "intro" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("原本")]),
                      _c("div", { staticClass: "text" }, [
                        _c("p", [
                          _vm._v(
                            " 提供原创作品版权确权、版权交易、侵权监测、取证维权的一站式版权服务。通过区块链技术实现快速低成本版权确权，鹰眼系统实时扫描全网侵权行为，并对侵权行为进行实时电子取证固证。原本DNA实现了原创作品全网溯源，为原创者提供全新的版权变现手段。 "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "原本已服务于经济日报、人民政协报、浙报集团、成都报业、金融时报、解放日报、界面、第一财经、江苏版权服务中心、中国财经媒体版权保护联盟等数百家媒体集团和机构。"
                          ),
                        ]),
                      ]),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://yuanben.io/",
                            target: "_blank",
                          },
                        },
                        [
                          _c("div", { staticClass: "btn clickable" }, [
                            _vm._v(" 查看详情 "),
                            _c("div", { staticClass: "indicator" }, [
                              _c("div"),
                              _c("div"),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "img" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/yuanben-platform.png"),
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "edge-4" }),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { position: "relative" },
                attrs: { id: "hetu-capital-div" },
              },
              [
                _c("section", {
                  staticStyle: { position: "absolute", top: "-80px" },
                  attrs: { id: "hetu-capital" },
                }),
                _c("div", { staticClass: "hetu-funds" }, [
                  _c("div", { staticClass: "header" }, [_vm._v(" 河图创投 ")]),
                  _c("div", { staticClass: "sub-header" }, [
                    _vm._v(" 以计算赋能创新 "),
                  ]),
                  _c("div", { staticClass: "intro" }, [
                    _c("p", [
                      _vm._v(
                        "河图创投（Hetu Capital）是一家全新的数据驱动投资的CVC。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "基于因问科技的数字知识产权保护生态，河图创投致力于激励小、微型科技企业的知识产权保护和转化。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "保护创新、激励创新、赋能创新，扶持真正致力于研发的初创公司。"
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "intro small" }, [
                    _vm._v(
                      " 河图创投（Hetu Capital）是一家全新的数据驱动投资的CVC。基于因问科技的数字知识产权保护生态，河图创投致力于激励小、微型科技企业的知识产权保护和转化。 保护创新、激励创新、赋能创新，扶持真正致力于研发的初创公司。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass: "hetu_decorator",
                    attrs: { src: require("@/assets/hetu_decorator.png") },
                  }),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { position: "relative" },
                attrs: { id: "technologies-div" },
              },
              [
                _c("section", {
                  staticStyle: { position: "absolute", top: "-80px" },
                  attrs: { id: "technologies" },
                }),
                _c("div", { staticClass: "tech-header" }, [
                  _c("div", { staticClass: "tech-title" }, [_vm._v("技术")]),
                  _c("img", {
                    attrs: { src: require("@/assets/prd_title_decorate.png") },
                  }),
                ]),
                _c("div", { staticClass: "techs" }, [
                  _c("div", { staticClass: "item delta" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/delta-logo.png") },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("Delta")]),
                    _c("div", { staticClass: "intro" }, [
                      _vm._v(
                        "开源的区块链隐私计算框架。通过封装整合联邦学习、安全多方计算、差分隐私等最新的隐私计算技术，降低了开发门槛，使用者无需了解隐私计算技术，也可快速实现计算需求。 用户可以快速地部署Delta节点，搭建隐私计算网络，联合多方数据，完成隐私计算。"
                      ),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://deltampc.com",
                          target: "_blank",
                        },
                      },
                      [
                        _c("div", { staticClass: "link clickable" }, [
                          _vm._v("查看更多"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item yuanbenlian" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/yuanbenlian-logo.png") },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("原本链")]),
                    _c("div", { staticClass: "intro" }, [
                      _vm._v(
                        "自主研发的企业级高性能联盟链。Layer 2扩容技术可达到百万TPS处理速度。整合非中心化存储层，支持大量数据直接上链，无需担心数据丢失。底层实现隐私计算算法支持，可运行各种隐私保护应用。完善的SDK、文档和工具库提高易用性，方便企业用户快速接入。 "
                      ),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://yuanbenlian.com",
                          target: "_blank",
                        },
                      },
                      [
                        _c("div", { staticClass: "link clickable" }, [
                          _vm._v("查看更多"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item tuling" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/tuling-logo.png") },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("图灵")]),
                    _c("div", { staticClass: "intro" }, [
                      _vm._v(
                        " 分布式智能计算引擎。整合最新的深度神经网络和NLP自然语言处理技术，实现高准确率的文本内容分析，支持主体识别、内容分类、摘要提取、内容推荐等任务。支持企业知识图谱构建，并通过知识图谱协助企业内智能计算任务。流式处理引擎具备单日亿级的任务处理能力。 "
                      ),
                    ]),
                    _c("a", { attrs: { href: "javascript:void(0)" } }, [
                      _c("div", { staticClass: "link clickable" }, [
                        _vm._v("查看更多"),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { position: "relative" },
                attrs: { id: "partners-div" },
              },
              [
                _c("section", {
                  staticStyle: { position: "absolute", top: "-80px" },
                  attrs: { id: "partners" },
                }),
                _c("div", { staticClass: "partners" }, [
                  _c("img", {
                    staticClass: "line-bg",
                    attrs: { src: require("@/assets/xiantiao.svg") },
                  }),
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 1320 750",
                        version: "1.1",
                      },
                    },
                    [
                      _c("ellipse", {
                        staticStyle: { fill: "#F6F7F9" },
                        attrs: { cx: "660", cy: "375", rx: "660", ry: "375" },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "header" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/prd_title_decorate.png"),
                      },
                    }),
                    _c("div", { staticClass: "title" }, [_vm._v("合作伙伴")]),
                  ]),
                  _c("div", { staticClass: "logos" }, [
                    _c("div", { staticClass: "line1" }, [
                      _c("img", {
                        staticClass: "logo-science-insititution",
                        attrs: {
                          src: require("@/assets/logo-science-insititution.png"),
                        },
                      }),
                      _c("img", {
                        staticClass: "logo-fudan",
                        attrs: { src: require("@/assets/logo-fudan.png") },
                      }),
                      _c("img", {
                        staticClass: "logo-wipo",
                        attrs: { src: require("@/assets/logo-wipo.png") },
                      }),
                    ]),
                    _c("div", { staticClass: "line2" }, [
                      _c("img", {
                        staticClass: "logo-yangtz-river-delta",
                        attrs: {
                          src: require("@/assets/logo-yangtz-river-delta.png"),
                        },
                      }),
                      _c("img", {
                        staticClass: "logo-xian-construction-group",
                        attrs: {
                          src: require("@/assets/logo-xian-construction-group.png"),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "line3" }, [
                      _c("img", {
                        staticClass: "logo-omni-directions",
                        attrs: {
                          src: require("@/assets/logo-omni-directions.png"),
                        },
                      }),
                      _c("img", {
                        staticClass: "logo-duan_duan",
                        attrs: { src: require("@/assets/logo-duan_duan.png") },
                      }),
                      _c("img", {
                        staticClass: "logo-software-center",
                        attrs: {
                          src: require("@/assets/logo-software-center.png"),
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "logos mobile" }, [
                    _c("div", { staticClass: "line1" }, [
                      _c("img", {
                        staticClass: "logo-fudan",
                        attrs: { src: require("@/assets/logo-fudan.png") },
                      }),
                      _c("img", {
                        staticClass: "logo-science-insititution",
                        attrs: {
                          src: require("@/assets/logo-science-insititution.png"),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "line2" }, [
                      _c("img", {
                        staticClass: "logo-wipo",
                        attrs: { src: require("@/assets/logo-wipo.png") },
                      }),
                      _c("img", {
                        staticClass: "logo-yangtz-river-delta",
                        attrs: {
                          src: require("@/assets/logo-yangtz-river-delta.png"),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "line3" }, [
                      _c("img", {
                        staticClass: "logo-xian-construction-group",
                        attrs: {
                          src: require("@/assets/logo-xian-construction-group.png"),
                        },
                      }),
                      _c("img", {
                        staticClass: "logo-omni-directions",
                        attrs: {
                          src: require("@/assets/logo-omni-directions.png"),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "line4" }, [
                      _c("img", {
                        staticClass: "logo-duan_duan",
                        attrs: { src: require("@/assets/logo-duan_duan.png") },
                      }),
                      _c("img", {
                        staticClass: "logo-software-center",
                        attrs: {
                          src: require("@/assets/logo-software-center.png"),
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("el-footer", [
            _c("div", { staticClass: "footer" }, [
              _c("div", { staticClass: "left company" }, [
                _c("div", { staticClass: "name" }, [_vm._v(" 因问科技 ")]),
                _c("img", {
                  staticStyle: { width: "124px", height: "124px" },
                  attrs: { src: require("@/assets/contact.png") },
                }),
              ]),
              _c("div", { staticClass: "right our-products" }, [
                _c("div", { staticClass: "inner" }, [
                  _c("h2", [_vm._v("我们的产品")]),
                  _c("div", [_vm._v("Miks")]),
                  _c("div", [_vm._v("原本版权保护")]),
                  _c("div", [_vm._v("原本区块链")]),
                  _c("div", [_vm._v("Delta 隐私计算平台")]),
                ]),
              ]),
              _c("div", { staticClass: "right about" }, [
                _c("div", { staticClass: "inner" }, [
                  _c("h2", [_vm._v("关于因问")]),
                  _c("div", [_vm._v("首页")]),
                  _c("div", [_vm._v("解决方案")]),
                  _c("div", [_vm._v("技术服务")]),
                ]),
              ]),
              _c("div", { staticClass: "right contact" }, [
                _c("div", { staticClass: "inner" }, [
                  _c("h2", [_vm._v("联系我们")]),
                  _c("div", [_vm._v("公众号：yuanbenio")]),
                  _c("div", [_vm._v("邮箱：hi@yuanben.org ")]),
                  _c("div", [_vm._v("地址：浦东新区泥城镇云汉路979号2楼")]),
                ]),
              ]),
              _c("div", { staticStyle: { clear: "both" } }),
            ]),
            _c("div", { staticClass: "footer mobile" }, [
              _c("div", { staticClass: "name" }, [
                _c("img", {
                  staticClass: "logo-text",
                  attrs: { src: require("@/assets/zibiao.svg") },
                }),
              ]),
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "inner" }, [
                  _c("h2", [_vm._v("我们的产品")]),
                  _c("div", [_vm._v("Miks")]),
                  _c("div", [_vm._v("原本版权保护")]),
                  _c("div", [_vm._v("原本区块链")]),
                  _c("div", [_vm._v("Delta 隐私计算平台")]),
                ]),
                _c("div", { staticClass: "inner" }, [
                  _c("h2", [_vm._v("关于因问")]),
                  _c("div", [_vm._v("首页")]),
                  _c("div", [_vm._v("解决方案")]),
                  _c("div", [_vm._v("技术服务")]),
                ]),
              ]),
              _c("div", { staticClass: "contact" }, [
                _c("div", { staticClass: "inner" }, [
                  _c("h2", [_vm._v("联系我们")]),
                  _c("div", [_vm._v("公众号：yuanbenio")]),
                  _c("div", [_vm._v("邮箱：hi@yuanben.org ")]),
                  _c("div", [_vm._v("地址：浦东新区泥城镇云汉路979号2楼")]),
                  _c("img", {
                    staticStyle: { width: "124px", height: "124px" },
                    attrs: { src: require("@/assets/contact.png") },
                  }),
                ]),
              ]),
              _c("div", { staticStyle: { clear: "both" } }),
            ]),
            _c("div", { staticClass: "bottom-footer" }, [
              _c("div", { staticClass: "record" }, [
                _c("span", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://beian.miit.gov.cn/",
                        target: "_blank",
                      },
                    },
                    [_vm._v("沪ICP备2021016007号-3")]
                  ),
                ]),
                _c("span", [
                  _vm._v(" | © 2021 因问科技 版权所有 保留一切权利"),
                ]),
              ]),
              _c("div", { staticClass: "lang" }, [_vm._v(" 简体中文 ")]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }