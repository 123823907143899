<template>
   <div class="nav">
      <div class="logo">
         <img class="logo-text" :src="require('@/assets/zibiao.png')" />
      </div>
      <div class="menus">
         <ul class="menu">
            <li role="menuitem" tabindex="0" class="menu-item clickable"><a href="#server">服务基座</a></li>
            <li role="menuitem" tabindex="0" class="menu-item clickable"><a href="#solutions">解决方案</a></li>
            <li role="menuitem" tabindex="0" class="menu-item clickable"><a href="#hetu-capital">河图创投</a></li>
            <li role="menuitem" tabindex="0" class="menu-item clickable"><a href="#technologies">技术突破</a></li>
            <li role="menuitem" tabindex="0" class="menu-item clickable"><a href="#partners">合作伙伴</a></li>
         </ul>
         <img class="drawer" v-if="!visible" :src="require('@/assets/caidan.svg')" style="width:26px;height:30px"
            @click="visible=true" />
         <i v-else class="el-icon-close" @click="visible=false"></i>
      </div>
      <el-drawer :withHeader="false" direction="ttb" size="100%" :visible="visible" :show-close="true">
         <ul class="menu mobile">
            <a @click="close('#server')" >
               <li role="menuitem" tabindex="0" class="menu-item clickable">服务基座</li>
            </a>
            <a @click="close('#solutions')" >
               <li role="menuitem" tabindex="0" class="menu-item clickable">解决方案</li>
            </a>
            <a @click="close('#hetu-capital')" >
               <li role="menuitem" tabindex="0" class="menu-item clickable">河图创投</li>
            </a>
            <a @click="close('#technologies')" >
               <li role="menuitem" tabindex="0" class="menu-item clickable">技术突破</li>
            </a>
            <a @click="close('#partners')">
               <li role="menuitem" tabindex="0" class="menu-item clickable">合作伙伴</li>
            </a>
         </ul>
      </el-drawer>
   </div>
</template>

<script>
export default {
   name: "HomeNav",
   data() {
      return {
         visible: false,
      };
   },
   watch:{
      visible:function(nV,oV) {
         if(nV) {
            document.querySelector('.el-drawer__wrapper').removeEventListener('click',this.closeDrawer)
            document.querySelector('.el-drawer__wrapper').addEventListener('click',this.closeDrawer)
         }
      }
   },
   methods: {
      close(href) {
         window.location.href = href;
         this.visible = false;
      },
      closeDrawer(){
         this.visible = false;
      }
   },
   props: {
      msg: String,
   },
   
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus">
@media only screen and (max-width: 516px) {
   .v-modal {
      top: 80px !important;
   }
}
</style>
<style lang="stylus" scoped>
.nav {
   height: 100%;

   .logo {
      float: left;
      display: flex;
      align-items: center;
      padding-left: 120px;

      .logo-text {
         margin: 22px auto 20px -4px;
         width: 186px;
      }
   }

   .drawer {
      display: none;
   }

   .el-menu--horizontal>.el-menu-item.is-active, .menus {
      float: right;
      padding-right: 120px;
      height: 100px;
      display: flex;
      align-items: center;

      ul {
         padding-left: 0px;

         li {
            float: left;
            margin-left: 40px;
            list-style: none;
            font-size: 18px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            line-height: 25px;

            &:first-of-type {
               margin-left: 0px;
            }
         }
      }
   }

   &:after {
      clear: both;
   }
}

@media only screen and (min-width: 538px) and (max-width: 1080px) {
   .nav {
      position: fixed;
      top: 0;

      .logo {
         float: none;
         padding-left: 40px;
      }

      .menus {
         float: none;
         padding-left: 40px;
         padding-right: 0px;
         height: auto;

         ul {
            li {
               margin-left: 20px;
            }
         }
      }
   }
}

@media only screen and (max-width: 720px) and (min-width: 516px) {
   .nav {
      .logo {
         float: none;
         padding-left: 40px;
      }

      .menus {
         float: none;
         padding-left: 40px;
         padding-right: 0px;
         height: auto;

         ul {
            li {
               margin-left: 20px;
            }
         }
      }
   }
}

@media only screen and (max-width: 516px) {
   .nav {
      height: 70px;

      .logo {
         padding-left: 0px;

         .logo-text {
            width: 132px;
            height: 22px;
         }
      }

      .logo-img {
         margin-left: 40px;
         margin-bottom: 20px;
      }

      .menus {
         height: 64px;
         padding-right: 0px;

         i {
            font-size: 32px;
         }

         .menu {
            display: none;
         }
      }

      ul {
         &.menu {
            text-align: left;

            li {
               float: none;
               margin-left: 20px;
               margin-top: 16px;
               margin-bottom: 32px;
               font-size: 20px;
               font-family: PingFang-SC-Heavy, PingFang-SC;
               font-weight: 800;
               color: #222222;
               line-height: 28px;
               outline: none;

               a {
                  color: #222;
                  text-decoration: none;
               }

               &.menu-item {
                  margin-left: 0px;
               }
            }
         }
      }

      .drawer {
         display: inherit;
      }
   }

   >>>.el-drawer__header {
      display: none;
   }

   >>>.el-drawer__wrapper {
      top: 60px !important;

      .el-drawer__container {
         height: 320px !important;
      }
   }

   >>>.el-drawer__body {
      text-align: left;
   }
}
</style>
<style lang="stylus">
ul {
   &.menu {
      padding-left: 0px;
      margin-left: 20px;

      a {
         text-decoration: none;
         color: #000000;

         &:hover {
            color: #3358EF;
         }
         &:active {
            color: #3358EF;
         }
         &:touchstart {
            color: #3358EF;
         }
      }

      li {
         float: left;
         list-style: none;
         margin-bottom: 20px;
         font-size: 18px;
         font-family: PingFang-SC-Bold, PingFang-SC;
         font-weight: bold;
         line-height: 25px;
      }
   }
}
</style>


